@import 'variables';

$rt-color-warning: rgb(255, 244, 206);
$rt-color-error: rgb(253, 231, 233);
$rt-toast-min-height: 32px;
$rt-toast-width: 100%;

@import '~react-toastify/scss/main';
.Toastify {
  width: 100%;
  .Toastify__toast-container {
    color: $black;
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
    .Toastify__toast {
      box-shadow: none;
      border-bottom: 1px solid $gray-200;
      margin-bottom: 0;
      &:first-child {
        border-top: 1px solid $gray-200;
      }
      .Toastify__close-button {
        color: $black;
      }
    }
  }
}
