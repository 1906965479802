.paymentSuccessSummaryForm {
  .paid {
    font-weight: bold;
    font-size: 1.3rem;
  }

  .description {
    font-weight: bold;
  }

  .reference {
    font-weight: bold;
  }
}