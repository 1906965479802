@import '../../../variables';

.paymentOpenSummaryForm {
  .payment-open {
    color: $red;
    font-weight: bold;
    font-size: 1.3rem;
  }

  .description {
    font-weight: bold;
  }

  .reference {
    font-weight: bold;
  }

  .status {
    font-weight: bold;
  }
}