@import '../../variables';

$margin: 15px;

.ideal-container {
  display: flex;
  flex-direction: column;

  .logo {
    border-bottom: 1px solid $gray-100;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .spinner {
    margin: auto;
  }

  .header {
    margin-bottom: $margin;
    margin-top: $margin;
    .col {
      padding-left: 0;
    }
  }

  .content {
    flex: auto;
    height: 300px;

    .summary {
      background-color: $blue;
      margin-right: $margin;
      padding: 30px;
    }

    .bankselection {
      background-color: $gray-200;
      margin-left: $margin;
      padding: 30px;
    }
  }


  .footer {
    flex-direction: row-reverse;
    margin-top: $margin;

    .cancelButton {
      margin-right: $margin;
    }
  }
}

