@import '../../variables';

$margin: 15px;

.paymentOpen-container {
  .logo {
    border-bottom: 1px solid $gray-100;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .header {
    margin-bottom: $margin;
    margin-top: $margin;
    .col {
      padding-left: 0;
    }
  }

  .content {
    flex: auto;
    height: 340px;

    .summary {
      background-color: $blue;
      padding: 30px;
    }
  }

  .footer {
    flex-direction: row-reverse;
    margin-top: $margin;

    .cancelButton {
      margin-right: $margin;
    }
  }
}