@import '../../variables';

$margin: 15px;

.paymentDone-container {
  .logo {
    border-bottom: 1px solid $gray-100;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .header {
    margin-bottom: $margin;
    margin-top: $margin;
    .col {
      padding-left: 0;
    }
  }
}